import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, inject, LOCALE_ID, provideAppInitializer } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import { appRoutes, I18n } from '@dmb/dmb-ui';
import { TraceService } from '@sentry/angular';
import * as Sentry from '@sentry/angular';
import { environment } from '@dmb/dmb-ui';

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['prod', /^https:\/\/kontor-new-media-gmbh.sentry\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
console.log('appConfig');
const provideSentry = () => {
  if (environment.sentryDsn) {
    if (environment.sentryDsn) {
      Sentry.init({
        dsn: environment.sentryDsn,
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['prod', /^https:\/\/kontor-new-media-gmbh.sentry\.io\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
    return [
      { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
      { provide: TraceService, deps: [Router] },
      provideAppInitializer(() => {
        inject(TraceService);
      }),
      
    ];
  }
  return [];
};
export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAppInitializer(() => inject(I18n).setLocale(null)),
    { provide: LOCALE_ID, useFactory: () => inject(I18n).locale },
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
    ),
    provideSentry()
  ],
};
